import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import IconButton from "./ui/button/IconButton";
import { CiCircleCheck, CiCircleRemove, CiTrash, CiEdit } from "react-icons/ci";
import { SiMicrosoftexcel } from "react-icons/si";
import { PiFilePdfDuotone } from "react-icons/pi";
import Title from "./ui/textual/Title";
import Text from "./ui/textual/Text";
import { useData } from "../contexts/DataContext";
import { confirm } from "../components/confirm/ConfirmGlobal";
import { generateClient } from "aws-amplify/api";
import {
  notifySuccess,
  notifyError,
} from '../components/ui/Toastify';
import * as Tool from "../tools/Calculate";
import Pastille from "./ui/textual/Pastille";
import Checkbox from "./ui/checkbox/CheckboxItem";
import Table from './ui/table/Table';
import Th from './ui/table/Th';
import Td from './ui/table/Td';
import { useUser } from '../contexts/UserContext';
import Stack from './box/container/Stack';
import { IoMdAdd } from 'react-icons/io';
import AnimationComponent from './animation/Animation';
import Empty from './animation/storageAnimation/empty.json';
import {
  deleteTasks
} from "../graphql/mutations";
import ColoredSelect from './ui/form/ColoredSelect';
import { dateConverter } from '../tools/dateConverter';
import Tips from './ui/textual/Tips';
import { usePopup } from '../contexts/PopupContext';
import AddTaskPopup from './ui/popup/allPopups/AddTaskPopup';
import Span from './ui/textual/Span';
import SmallCircleProgress from './ui/progress-bar/SmallCircleProgress';
import ViewTaskPopup from './ui/popup/allPopups/ViewTaskPopup';
import EditTaskPopup from './ui/popup/allPopups/EditTaskPopup';
import Link from "./ui/textual/Link";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import Select from './ui/form/Select';
import EditAuditPopup from './ui/popup/allPopups/EditAuditPopup';
import { capitalizeFirstLetter } from '../tools/capitalizeFirstLetter';

const client = generateClient();

const DivTrash = styled.div`
  display: flex;
  margin: -20px 20px;
  justify-content: flex-end;
  width: 100%;
`;

export default function AuditCard({ audit, logo, clientName }) {
  const { popups, openPopup, closePopup } = usePopup();
  const [tasks, setTasks] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [deleteButtonState, setDeleteButtonState] = useState("notactive");

  const {
    deleteAnAudit,
    updateATask,
    deleteATask,
    refresh,
    setRefresh,
  } = useData();

  const { isAdmin } = useUser();

  useEffect(() => {
    if (audit) {
      setTasks(audit.tasks.items);
    }
  }, [audit]);

  useEffect(() => {
    if (selectedCount > 0) {
      setDeleteButtonState("active");
    } else {
      setDeleteButtonState("notactive");
    }
  }, [selectedCount]);

  useEffect(() => {
    const count = Object.values(checkedItems).filter(value => value).length;
    setSelectedCount(count);
    setAllChecked(count === tasks?.length);
  }, [checkedItems, tasks]);

  const handleTaskUpdate = async (task, done) => {
    try {
      await updateATask(task.id, done);
      notifySuccess('Tâche mise à jour');
      setRefresh(!refresh);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la tâche :", error);
    }
  };

  const handleChildCheckboxChange = (taskId) => {
    const updatedCheckedItems = { ...checkedItems, [taskId]: !checkedItems[taskId] };
    setCheckedItems(updatedCheckedItems);
  };

  const handleParentCheckboxChange = () => {
    const updatedCheckedItems = {};
    if (!allChecked) {
      tasks.forEach((task) => {
        updatedCheckedItems[task.id] = true;
      });
    }
    setCheckedItems(updatedCheckedItems);
    setAllChecked(!allChecked);
  };

  const deleteCheckedTasks = async () => {
    const checkedTaskIds = Object.keys(checkedItems).filter(taskId => checkedItems[taskId]);
    try {
      if (await confirm({
        title: "Voulez-vous vraiment supprimer ces tâches ?",
        content: "Ces tâches seront supprimées définitivement et personne ne pourra y accéder",
        variant: "danger"
      })) {
        await Promise.all(checkedTaskIds.map(async taskId => {
          await deleteTask(taskId);
        }));
        setRefresh(!refresh);
        notifySuccess(`${checkedTaskIds.length} tâches supprimées avec succès`);
        setCheckedItems({});
      }
    } catch (error) {
      console.error("Erreur lors de la suppression des tâches :", error);
      notifyError("Erreur lors de la suppression des tâches");
    }
  };

  const deleteTask = async (taskId) => {
    try {
      const deletedTask = await client.graphql({
        query: deleteTasks,
        variables: {
          input: {
            id: taskId
          }
        }
      });
      console.log("Tâche supprimée :", deletedTask);
    } catch (error) {
      console.error("Erreur lors de la suppression de la tâche :", error);
      throw error;
    }
  };

  async function handleDeleteAudit(id) {
    if (await confirm({
      title: "Voulez-vous vraiment supprimer cet audit ?",
      content: "Cet audit et ses tâches seront supprimés définitivement.",
      variant: "danger"
    })) {
      try {
        await deleteAnAudit(id);
        setRefresh((prev) => !prev);
      } catch (error) {
        console.error("error on deleting audit", error);
        notifyError("Erreur lors de la suppression de l'audit");
      }
    }
  }

  const totalTasks = tasks ? tasks.length : 0;
  const doneTasks = tasks ? tasks.filter(task => task.done).length : 0;
  const donePercentage = totalTasks ? (doneTasks / totalTasks) * 100 : 0;

  const exportToExcel = () => {
    const data = tasks.map(task => ({
      Tâche: task.name,
      Description: task.description,
      Date: task.date,
      Statut: task.done ? 'Terminée' : 'Non terminée'
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tâches");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, `${clientName}_${audit.name}.xlsx`);
  };


  const exportToPDF = async () => {
    const doc = new jsPDF();

    doc.setFontSize(22);
    doc.text(`${capitalizeFirstLetter(clientName)}`, 10, 10);

    doc.setFontSize(12);
    doc.text(`Date d'exportation: ${dateConverter(new Date(), 'short')}`, 10, 20);

    doc.line(10, 25, 200, 25);

    doc.setFontSize(16);
    const auditTitle = `${audit.name} (échéance le ${dateConverter(audit.targetDate, 'short')})`;
    const pageWidth = doc.internal.pageSize.getWidth();
    const splitTitle = doc.splitTextToSize(auditTitle, pageWidth - 20);

    let currentY = 35;

    splitTitle.forEach((line) => {
      doc.text(line, 10, currentY);
      currentY += 6;
    });

    currentY += 0;

    const tableColumn = ["Tâche", "Date", "Statut"];
    const tableRows = [];

    if (Array.isArray(audit.tasks?.items)) {
      const sortedTasks = audit.tasks.items.sort((a, b) => new Date(a.date) - new Date(b.date));

      sortedTasks.forEach(task => {
        const taskData = [
          task.name,
          dateConverter(task.date, 'short'),
          task.done ? "Terminée" : "Non terminée"
        ];
        tableRows.push(taskData);
      });
    }

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: currentY,
      margin: { left: 10, right: 10 },
      styles: { cellPadding: 2, fontSize: 10, overflow: 'linebreak' },
      columnStyles: {
        0: { cellWidth: 100 },
      },
    });

    doc.save(`${capitalizeFirstLetter(clientName)}_${audit.name}.pdf`);
  };




  const handleSelectOption = (option) => {
    switch (option.id) {
      case 'delete':
        handleDeleteAudit(audit.id);
        break;
      case 'excel':
        exportToExcel();
        break;
      case 'pdf':
        exportToPDF();
        break;
      default:
        break;
    }
  };

  const options = [
    { id: 'excel', label: 'Exporter en CSV', icon: SiMicrosoftexcel },
    { id: 'pdf', label: 'Exporter en PDF', icon: PiFilePdfDuotone },
  ];
  
  if (isAdmin) {
    options.push({
      id: 'delete',
      label: 'Supprimer',
      variant: 'danger',
      icon: CiTrash,
    });
  }


  return (
    <Stack direction="column">
      <EditAuditPopup
        open={popups[`edit ${audit?.id}`]}
        onClose={() => closePopup(`edit ${audit?.id}`)}
        auditId={audit?.id}
      />
        <DivTrash>
          <Select
            options={options}
            onSelect={handleSelectOption}
            defaultText="Sélectionner une action"
            variant="keepDefault"
          />
        </DivTrash>
      <Stack>
        {logo ? (
          <img src={logo} className="user-picture" alt="logo" />
        ) : (
          <img src="/illustration/svg/utilisateur.svg" className="user-picture" alt="avatar" />
        )}
        <Stack direction={"column"} spacing={"2px"}>
          <Title level={3}>{audit.name} pour <Span textTransform="capitalize">{clientName}</Span></Title>
          <Text>{`Créé le ${dateConverter(audit.createdAt)}`}</Text>
        </Stack>
        {isAdmin && (
          <IconButton
            wtext={"no"}
            variant="secondary-action"
            onClick={() => openPopup(`edit ${audit?.id}`)}
          >
            <CiEdit />
          </IconButton>
        )}
      </Stack>
      <Stack align={"center"}>
        <Pastille variant="success">{audit.type}</Pastille>
        <Pastille variant="warning">{audit.status}</Pastille>
        <Pastille variant="primary">{`Se termine le ${dateConverter(audit.targetDate)}`}</Pastille>
        <SmallCircleProgress width="80px" progress={donePercentage.toFixed(2)} showDecimals={false} />
      </Stack>
      {isAdmin && (
        <Stack justify="end">
          <IconButton variant="danger" onClick={deleteCheckedTasks} enable={deleteButtonState}>
            <CiTrash /> Supprimer ({selectedCount})
          </IconButton>
          <IconButton variant="action" onClick={() => openPopup(audit?.id)}>
            <IoMdAdd /> Ajouter une Tâche
          </IconButton>
        </Stack>
      )}
      {tasks && tasks.length > 0 ? (
        <Table>
          <thead>
            <tr>
              {isAdmin && (
                <Th variant="basique">
                  <Checkbox
                    type="checkbox"
                    checked={allChecked}
                    onChange={handleParentCheckboxChange}
                  />
                </Th>
              )}
              <Th variant="basique">Tâche</Th>
              <Th variant="basique">Date</Th>
              {isAdmin && (
                <Th variant="basique">Statut</Th>
              )}
              {isAdmin && (
                <Th variant="basique">Actions</Th>
              )}
            </tr>
          </thead>
          <tbody>
            {tasks.map((task) => (
              <tr key={task.id}>
                <ViewTaskPopup
                  open={popups[`view ${task?.id}`]}
                  onClose={() => closePopup(`view ${task?.id}`)}
                  taskId={task?.id}
                />
                <EditTaskPopup
                  open={popups[`edit ${task?.id}`]}
                  onClose={() => closePopup(`edit ${task?.id}`)}
                  taskId={task?.id}
                />
                {isAdmin && (
                  <Td>
                    <Checkbox
                      type="checkbox"
                      checked={checkedItems[task.id] || false}
                      onChange={() => handleChildCheckboxChange(task.id)}
                    />
                  </Td>
                )}
                <Td><Link onClick={() => openPopup(`view ${task?.id}`)}>{task.name}</Link></Td>
                <Td>
                  <Pastille variant={Tool.defColorDate(task).color}>
                    {Tool.defColorDate(task).icon}{Tool.dateToFRFormat(task)}
                  </Pastille>
                </Td>
                {isAdmin && (
                  <Td>
                    <ColoredSelect
                      options={[
                        { id: 'done', label: 'Terminée', variant: 'success', icon: CiCircleCheck },
                        { id: 'not_done', label: 'Non terminée', variant: 'danger', icon: CiCircleRemove },
                      ]}
                      value={task.done ? 'done' : 'not_done'}
                      onSelect={(option) => handleTaskUpdate(task, option.id === 'done')}
                      defaultText="Sélectionner..."
                    />
                  </Td>
                )}
                {isAdmin && (
                  <Td>
                    <Stack>
                      <IconButton
                        wtext={"no"}
                        variant="secondary-action"
                        onClick={() => openPopup(`edit ${task?.id}`)}
                      >
                        <CiEdit />
                      </IconButton>
                      <IconButton
                        wtext={"no"}
                        variant="danger"
                        onClick={async () => {
                          try {
                            await deleteATask(task.id);
                            setRefresh((prev) => !prev);
                          } catch (error) {
                            console.error('Erreur lors de la suppression de la tâche :', error);
                          }
                        }}
                      >
                        <CiTrash />
                      </IconButton>
                    </Stack>
                  </Td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Stack direction="column" align="center">
          <AnimationComponent animationData={Empty} width="150px" />
          <Text>
            Cet audit n'a aucune tâche
          </Text>
          {isAdmin ? (
            <IconButton variant="action" onClick={() => openPopup(audit?.id)}>
              <IoMdAdd /> Ajouter une tâche
            </IconButton>
          ) : (
            <Tips variant={"warning"}>Vous n'avez pas les droits pour ajouter une tâche, contactez un admin</Tips>
          )}
        </Stack>
      )}
      <AddTaskPopup
        open={popups[audit?.id]}
        onClose={() => closePopup(audit?.id)}
        auditId={audit?.id}
      />
    </Stack>
  );
}
