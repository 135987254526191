import React, { useState, useEffect } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import { getTasks } from '../../../../graphql/queries';
import { generateClient } from "aws-amplify/api";
import Pastille from '../../textual/Pastille';
import * as Tool from "../../../../tools/Calculate";
import LoadingSpinner from "../../../../states/LoadingSpinner";

const client = generateClient();


const ViewTaskPopup = ({ open, onClose, taskId }) => {
    const [task, setTask] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (open && taskId) {
            fetchTaskDetails(taskId);
        }
    }, [open, taskId]);

    async function fetchTaskDetails(id) {
        setLoading(true);
        setError(null);
        try {
            const taskData = await client.graphql({
                query: getTasks,
                variables: { id }
            });
            setTask(taskData.data.getTasks);
        } catch (error) {
            console.error("Erreur lors de la récupération de la tâche :", error);
            setError("Une erreur est survenue lors de la récupération de la tâche.");
        } finally {
            setLoading(false);
        }
    }

    return (
        <Popup open={open} onClose={onClose} title={`Vue de la tâche ${task?.name}`}>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <Text variant="error">{error}</Text>
            ) : task ? (
                <>
                    <Pastille variant={Tool.defColorDate(task).color}>
                       Date échéante : {Tool.defColorDate(task).icon}{Tool.dateToFRFormat(task)}
                    </Pastille>
                    <Text>Description : {task?.description}</Text>
                </>
            ) : (
                <Text>Aucune tâche trouvée.</Text>
            )}
        </Popup>
    );
};

export default ViewTaskPopup;
