import React, { useState, useEffect } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import { getAuditTemplate } from '../../../../graphql/queries';
import { generateClient } from "aws-amplify/api";
import LoadingSpinner from "../../../../states/LoadingSpinner";
import TextInput from '../../form/Textinput';
import Stack from '../../../box/container/Stack';
import Button from '../../button/Button';
import { useData } from '../../../../contexts/DataContext';
import { notifySuccess } from '../../Toastify';
import Tips from '../../textual/Tips';

const client = generateClient();

const EditAuditTemplatePopup = ({ open, onClose, auditTemplateId }) => {
    const { editAnAuditTemplate, refresh, setRefresh } = useData();
    const [audit, setaudit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [auditTemplateName, setAuditTemplateName] = useState('');
    const [auditTemplateType, setAuditTemplateType] = useState('');
    const [auditCycle, setAuditCycle] = useState('');

    useEffect(() => {
        if (open && auditTemplateId) {
            console.log(auditTemplateId)
            fetchauditDetails(auditTemplateId);
        }
    }, [open, auditTemplateId]);

    async function fetchauditDetails(id) {
        setLoading(true);
        setError(null);
        try {
            const auditTemplateData = await client.graphql({
                query: getAuditTemplate,
                variables: { id }
            });
            const fetchdaudit = auditTemplateData.data.getAuditTemplate;
            setaudit(fetchdaudit);

            setAuditTemplateName(fetchdaudit.name || '');
            setAuditTemplateType(fetchdaudit.type || '');
            setAuditCycle(fetchdaudit.cycleFrequency || '');
        } catch (error) {
            console.error("Erreur lors de la récupération de l'audit :", error);
            setError("Une erreur est survenue lors de la récupération de l'audit.");
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        if (auditTemplateName && auditCycle) {
            try {
                await editAnAuditTemplate(auditTemplateId, auditTemplateName, auditTemplateType, auditCycle);
                setRefresh(!refresh);
                onClose();
                notifySuccess("Le template a été mis à jour")
            } catch (error) {
                console.error("Erreur lors de la modification de l'audit :", error);
            }
        } else {
            alert("Veuillez remplir tous les champs requis.");
        }
    };

    return (
        <Popup open={open} onClose={onClose} title={`Modifier le template d'audit ${audit?.name}`}>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <Text variant="error">{error}</Text>
            ) : audit ? (
                <>
                    <Tips variant="warning">Modifier les informations du template ne modifiera pas les informations des audits déjà créés.</Tips>
                    <Stack direction={"column"} spacing={"15px"}>
                        <Stack direction={"column"}>
                            <TextInput
                                type={"text"}
                                label={"Nom de l'audit"}
                                value={auditTemplateName}
                                onChange={(e) => setAuditTemplateName(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <TextInput
                                type={"text"}
                                label={"Type"}
                                value={auditTemplateType}
                                onChange={(e) => setAuditTemplateType(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <TextInput
                                type={"number"}
                                label={"Durée du cycle en jours"}
                                value={auditCycle}
                                onChange={(e) => setAuditCycle(e.target.value)}
                                required
                                variant={"blue"}
                            />
                        </Stack>
                        <Button width="full-width" variant={"primary"} onClick={handleSubmit}>
                            Valider la modification
                        </Button>
                    </Stack>
                </>
            ) : (
                <Text>Aucun template d'audit trouvé.</Text>
            )}
        </Popup>
    );
};

export default EditAuditTemplatePopup;
