/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAuditTemplate = /* GraphQL */ `
  mutation CreateAuditTemplate(
    $input: CreateAuditTemplateInput!
    $condition: ModelAuditTemplateConditionInput
  ) {
    createAuditTemplate(input: $input, condition: $condition) {
      id
      name
      description
      type
      cycleFrequency
      tasksTemplates {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAuditTemplate = /* GraphQL */ `
  mutation UpdateAuditTemplate(
    $input: UpdateAuditTemplateInput!
    $condition: ModelAuditTemplateConditionInput
  ) {
    updateAuditTemplate(input: $input, condition: $condition) {
      id
      name
      description
      type
      cycleFrequency
      tasksTemplates {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAuditTemplate = /* GraphQL */ `
  mutation DeleteAuditTemplate(
    $input: DeleteAuditTemplateInput!
    $condition: ModelAuditTemplateConditionInput
  ) {
    deleteAuditTemplate(input: $input, condition: $condition) {
      id
      name
      description
      type
      cycleFrequency
      tasksTemplates {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTasksTemplate = /* GraphQL */ `
  mutation CreateTasksTemplate(
    $input: CreateTasksTemplateInput!
    $condition: ModelTasksTemplateConditionInput
  ) {
    createTasksTemplate(input: $input, condition: $condition) {
      id
      name
      description
      daysBeforeTargetDay
      createdAt
      updatedAt
      auditTemplateTasksTemplatesId
      __typename
    }
  }
`;
export const updateTasksTemplate = /* GraphQL */ `
  mutation UpdateTasksTemplate(
    $input: UpdateTasksTemplateInput!
    $condition: ModelTasksTemplateConditionInput
  ) {
    updateTasksTemplate(input: $input, condition: $condition) {
      id
      name
      description
      daysBeforeTargetDay
      createdAt
      updatedAt
      auditTemplateTasksTemplatesId
      __typename
    }
  }
`;
export const deleteTasksTemplate = /* GraphQL */ `
  mutation DeleteTasksTemplate(
    $input: DeleteTasksTemplateInput!
    $condition: ModelTasksTemplateConditionInput
  ) {
    deleteTasksTemplate(input: $input, condition: $condition) {
      id
      name
      description
      daysBeforeTargetDay
      createdAt
      updatedAt
      auditTemplateTasksTemplatesId
      __typename
    }
  }
`;
export const createReminderEmailTemplate = /* GraphQL */ `
  mutation CreateReminderEmailTemplate(
    $input: CreateReminderEmailTemplateInput!
    $condition: ModelReminderEmailTemplateConditionInput
  ) {
    createReminderEmailTemplate(input: $input, condition: $condition) {
      id
      subject
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReminderEmailTemplate = /* GraphQL */ `
  mutation UpdateReminderEmailTemplate(
    $input: UpdateReminderEmailTemplateInput!
    $condition: ModelReminderEmailTemplateConditionInput
  ) {
    updateReminderEmailTemplate(input: $input, condition: $condition) {
      id
      subject
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReminderEmailTemplate = /* GraphQL */ `
  mutation DeleteReminderEmailTemplate(
    $input: DeleteReminderEmailTemplateInput!
    $condition: ModelReminderEmailTemplateConditionInput
  ) {
    deleteReminderEmailTemplate(input: $input, condition: $condition) {
      id
      subject
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClients = /* GraphQL */ `
  mutation CreateClients(
    $input: CreateClientsInput!
    $condition: ModelClientsConditionInput
  ) {
    createClients(input: $input, condition: $condition) {
      id
      logo
      logoIdentityId
      email
      name
      description
      audits {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClients = /* GraphQL */ `
  mutation UpdateClients(
    $input: UpdateClientsInput!
    $condition: ModelClientsConditionInput
  ) {
    updateClients(input: $input, condition: $condition) {
      id
      logo
      logoIdentityId
      email
      name
      description
      audits {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClients = /* GraphQL */ `
  mutation DeleteClients(
    $input: DeleteClientsInput!
    $condition: ModelClientsConditionInput
  ) {
    deleteClients(input: $input, condition: $condition) {
      id
      logo
      logoIdentityId
      email
      name
      description
      audits {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAudit = /* GraphQL */ `
  mutation CreateAudit(
    $input: CreateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    createAudit(input: $input, condition: $condition) {
      id
      auditTemplateID {
        id
        name
        description
        type
        cycleFrequency
        createdAt
        updatedAt
        __typename
      }
      name
      type
      status
      targetDate
      realDate
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      clientsAuditsId
      auditAuditTemplateIDId
      __typename
    }
  }
`;
export const updateAudit = /* GraphQL */ `
  mutation UpdateAudit(
    $input: UpdateAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    updateAudit(input: $input, condition: $condition) {
      id
      auditTemplateID {
        id
        name
        description
        type
        cycleFrequency
        createdAt
        updatedAt
        __typename
      }
      name
      type
      status
      targetDate
      realDate
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      clientsAuditsId
      auditAuditTemplateIDId
      __typename
    }
  }
`;
export const deleteAudit = /* GraphQL */ `
  mutation DeleteAudit(
    $input: DeleteAuditInput!
    $condition: ModelAuditConditionInput
  ) {
    deleteAudit(input: $input, condition: $condition) {
      id
      auditTemplateID {
        id
        name
        description
        type
        cycleFrequency
        createdAt
        updatedAt
        __typename
      }
      name
      type
      status
      targetDate
      realDate
      tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      clientsAuditsId
      auditAuditTemplateIDId
      __typename
    }
  }
`;
export const createTasks = /* GraphQL */ `
  mutation CreateTasks(
    $input: CreateTasksInput!
    $condition: ModelTasksConditionInput
  ) {
    createTasks(input: $input, condition: $condition) {
      id
      tasksTemplateID {
        id
        name
        description
        daysBeforeTargetDay
        createdAt
        updatedAt
        auditTemplateTasksTemplatesId
        __typename
      }
      name
      description
      date
      done
      reminderEmailTemplateID {
        id
        subject
        body
        createdAt
        updatedAt
        __typename
      }
      sendReminderEmailDaysBeforeDate
      reminderEmailSentAt
      createdAt
      updatedAt
      auditTasksId
      tasksTasksTemplateIDId
      tasksReminderEmailTemplateIDId
      __typename
    }
  }
`;
export const updateTasks = /* GraphQL */ `
  mutation UpdateTasks(
    $input: UpdateTasksInput!
    $condition: ModelTasksConditionInput
  ) {
    updateTasks(input: $input, condition: $condition) {
      id
      tasksTemplateID {
        id
        name
        description
        daysBeforeTargetDay
        createdAt
        updatedAt
        auditTemplateTasksTemplatesId
        __typename
      }
      name
      description
      date
      done
      reminderEmailTemplateID {
        id
        subject
        body
        createdAt
        updatedAt
        __typename
      }
      sendReminderEmailDaysBeforeDate
      reminderEmailSentAt
      createdAt
      updatedAt
      auditTasksId
      tasksTasksTemplateIDId
      tasksReminderEmailTemplateIDId
      __typename
    }
  }
`;
export const deleteTasks = /* GraphQL */ `
  mutation DeleteTasks(
    $input: DeleteTasksInput!
    $condition: ModelTasksConditionInput
  ) {
    deleteTasks(input: $input, condition: $condition) {
      id
      tasksTemplateID {
        id
        name
        description
        daysBeforeTargetDay
        createdAt
        updatedAt
        auditTemplateTasksTemplatesId
        __typename
      }
      name
      description
      date
      done
      reminderEmailTemplateID {
        id
        subject
        body
        createdAt
        updatedAt
        __typename
      }
      sendReminderEmailDaysBeforeDate
      reminderEmailSentAt
      createdAt
      updatedAt
      auditTasksId
      tasksTasksTemplateIDId
      tasksReminderEmailTemplateIDId
      __typename
    }
  }
`;
export const createClientsContact = /* GraphQL */ `
  mutation CreateClientsContact(
    $input: CreateClientsContactInput!
    $condition: ModelClientsContactConditionInput
  ) {
    createClientsContact(input: $input, condition: $condition) {
      id
      picture
      client_id {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      name
      surname
      phone_number
      email
      birthdate
      createdAt
      updatedAt
      clientsContactClient_idId
      __typename
    }
  }
`;
export const updateClientsContact = /* GraphQL */ `
  mutation UpdateClientsContact(
    $input: UpdateClientsContactInput!
    $condition: ModelClientsContactConditionInput
  ) {
    updateClientsContact(input: $input, condition: $condition) {
      id
      picture
      client_id {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      name
      surname
      phone_number
      email
      birthdate
      createdAt
      updatedAt
      clientsContactClient_idId
      __typename
    }
  }
`;
export const deleteClientsContact = /* GraphQL */ `
  mutation DeleteClientsContact(
    $input: DeleteClientsContactInput!
    $condition: ModelClientsContactConditionInput
  ) {
    deleteClientsContact(input: $input, condition: $condition) {
      id
      picture
      client_id {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      name
      surname
      phone_number
      email
      birthdate
      createdAt
      updatedAt
      clientsContactClient_idId
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone_number
      bio
      profile_picture
      client {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      city
      website
      birthdate
      owner
      createdAt
      updatedAt
      profileClientId
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone_number
      bio
      profile_picture
      client {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      city
      website
      birthdate
      owner
      createdAt
      updatedAt
      profileClientId
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      name
      surname
      email
      phone_number
      bio
      profile_picture
      client {
        id
        logo
        logoIdentityId
        email
        name
        description
        createdAt
        updatedAt
        __typename
      }
      city
      website
      birthdate
      owner
      createdAt
      updatedAt
      profileClientId
      __typename
    }
  }
`;
export const createClientsStatus = /* GraphQL */ `
  mutation CreateClientsStatus(
    $input: CreateClientsStatusInput!
    $condition: ModelClientsStatusConditionInput
  ) {
    createClientsStatus(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClientsStatus = /* GraphQL */ `
  mutation UpdateClientsStatus(
    $input: UpdateClientsStatusInput!
    $condition: ModelClientsStatusConditionInput
  ) {
    updateClientsStatus(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClientsStatus = /* GraphQL */ `
  mutation DeleteClientsStatus(
    $input: DeleteClientsStatusInput!
    $condition: ModelClientsStatusConditionInput
  ) {
    deleteClientsStatus(input: $input, condition: $condition) {
      id
      label
      createdAt
      updatedAt
      __typename
    }
  }
`;
