import React, { useState, useEffect } from 'react';
import Popup from '../Popup';
import Text from '../../textual/Text';
import { getTasks } from '../../../../graphql/queries';
import { generateClient } from "aws-amplify/api";
import LoadingSpinner from "../../../../states/LoadingSpinner";
import TextInput from '../../form/Textinput';
import Textarea from '../../form/Textarea';
import DateInput from '../../form/DateInput';
import Stack from '../../../box/container/Stack';
import Button from '../../button/Button';
import { useData } from '../../../../contexts/DataContext';
import { notifySuccess } from '../../Toastify';
import { dateToAWSFormat } from '../../../../tools/Calculate';

const client = generateClient();

const EditTaskPopup = ({ open, onClose, taskId }) => {
    const { editATask, refresh, setRefresh } = useData();
    const [task, setTask] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [taskName, setTaskName] = useState('');
    const [taskDate, setTaskDate] = useState('');
    const [taskDescription, setTaskDescription] = useState('');

    useEffect(() => {
        if (open && taskId) {
            fetchTaskDetails(taskId);
        }
    }, [open, taskId]);

    async function fetchTaskDetails(id) {
        setLoading(true);
        setError(null);
        try {
            const taskData = await client.graphql({
                query: getTasks,
                variables: { id }
            });
            const fetchedTask = taskData.data.getTasks;
            setTask(fetchedTask);

            setTaskName(fetchedTask.name || '');
            setTaskDate(fetchedTask.date || '');
            setTaskDescription(fetchedTask.description || '');
        } catch (error) {
            console.error("Erreur lors de la récupération de la tâche :", error);
            setError("Une erreur est survenue lors de la récupération de la tâche.");
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async () => {
        if (taskName && taskDate) {
            try {
                const formattedDate = await dateToAWSFormat(taskDate);
                await editATask(taskId, taskName, formattedDate, taskDescription);
                setRefresh(!refresh);
                onClose();
                notifySuccess('La tâche a été mise à jour')
            } catch (error) {
                console.error("Erreur lors de la modification de la tâche :", error);
            }
        } else {
            alert("Veuillez remplir tous les champs requis.");
        }
    };

    return (
        <Popup open={open} onClose={onClose} title={`Modifier la tâche ${task?.name}`}>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <Text variant="error">{error}</Text>
            ) : task ? (
                <>
                    <Stack direction={"column"} spacing={"15px"}>
                        <Stack direction={"column"}>
                            <TextInput
                                type={"text"}
                                label={"Nom de la tâche"}
                                value={taskName}
                                onChange={(e) => setTaskName(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <DateInput
                                type={"text"}
                                label={"Date de la tâche"}
                                value={taskDate}
                                onChange={(e) => setTaskDate(e.target.value)}
                                required
                                variant={"blue"}
                            />
                            <Textarea
                                variant={"blue"}
                                label={"Description"}
                                value={taskDescription}
                                onChange={(e) => setTaskDescription(e.target.value)}
                                maxCharCount={250}
                            />
                        </Stack>
                        <Button width="full-width" variant={"primary"} onClick={handleSubmit}>
                            Valider la modification
                        </Button>
                    </Stack>
                </>
            ) : (
                <Text>Aucune tâche trouvée.</Text>
            )}
        </Popup>
    );
};

export default EditTaskPopup;
